const config: WhiteLabelConfig = {
    "class-name": "science",
    "home-title": "Looking for a Science Tutor?",
    "home-subtitle": "Search for a Specialist Science Tutor",
    "primary-text-colour-class": "science-primary-text-colour",
    "secondary-text-colour-class": "science-secondary-text-colour",
    "primary-colour": "#38B4BA",
    "secondary-colour": "#38B4BA",
    "primary-colour-mui": "#38B4BA",
    "secondary-colour-mui": "#000000",
    "name-colour": "#34A8AE",
    "chip-colour": "rgba(59, 118, 204, 1)",
    "footer-content": "Science Directory",
    "navbar-link": "Search science tutor",
    "filter-level-label": "Science level",
    "filter-level-values": {
        " ": "None",
        "7+/11+": "Science ( 7+/11+ )",
        "Primary": "Science ( Primary )",
        "Secondary": "Science ( Secondary )",
        "GCSE": "Science ( GCSE )",
        "A-level": "Science ( A-level )",
        "Degree/Adult": "Science ( Degree/Adult )"
    },
    "home-level-values": {
        " ": "Science ( All )",
        "7+/11+": "Science ( 7+/11+ )",
        "Primary": "Science ( Primary )",
        "Secondary": "Science ( Secondary )",
        "GCSE": "Science ( GCSE )",
        "A-level": "Science ( A-level )",
        "Degree/Adult": "Science ( Degree/Adult )"
    },
    "profile-step-0-title": "What level of science can you teach?",
    "search-result-title": "science",
    "message-colour": "#EBFAFD",

    "profile-step-3-title-placeholder": "E.g. Experienced and committed science tutor. Limit: 50 characters",
    'profile-step-3-about-us-placeholder': "Tell us about yourself e.g. experience as a science tutor, teaching style or approach. Limit: 190 words."
}

export default config;